import { LogoPropsType } from './logoTypes';

export default function GradleBuildToolShortLogo({ monochrome = false, className }: LogoPropsType) {
  return (
    <svg
      className={className}
      width="120"
      height="100"
      viewBox="0 0 120 100"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M104.448 24.6713C97.8311 18.0487 87.1531 17.8696 80.3173 24.2666C79.9883 24.5694 79.8004 24.9956 79.7988 25.4428C79.7895 25.8919 79.9629 26.3255 80.2794 26.6443L82.4674 28.8323C83.0506 29.413 83.9716 29.4776 84.6301 28.984C86.3456 27.6893 88.4378 26.992 90.587 26.9984C96.0841 26.9983 100.54 31.4545 100.541 36.9517C100.541 39.5885 99.4944 42.1176 97.6315 43.9838C83.8839 57.8073 65.4315 19.1824 23.6827 39.0513C20.8447 40.3696 19.6127 43.739 20.9309 46.577C20.9989 46.7232 21.073 46.8665 21.1532 47.0065L28.3115 59.3882C29.8658 62.0688 33.2819 63.0087 35.9885 61.5003L36.1656 61.3991L36.0265 61.5003L39.2009 59.717C42.742 57.5239 46.0889 55.0317 49.205 52.2678C49.8382 51.6772 50.8103 51.6448 51.4815 52.1919C52.18 52.7611 52.2847 53.7888 51.7155 54.4872C51.6456 54.573 51.5672 54.6514 51.4815 54.7214C48.2299 57.6497 44.7304 60.2902 41.0221 62.6133L40.9083 62.6765L37.7338 64.4472C36.3958 65.2054 34.8832 65.6021 33.3452 65.598C30.1015 65.6061 27.0991 63.8856 25.4659 61.0829L18.687 49.3968C5.68555 58.5788 -2.20637 76.2724 2.03045 98.6582C2.18214 99.4386 2.86692 100.001 3.66193 99.9988H11.3642C12.2179 100.031 12.9544 99.4049 13.0589 98.557C13.8661 92.3648 19.5401 87.9995 25.7323 88.8066C30.8175 89.4695 34.8197 93.4718 35.4826 98.557C35.5835 99.3709 36.2688 99.986 37.0888 99.9988H44.6013C45.4338 99.9995 46.1375 99.3824 46.2454 98.557C47.0249 92.3683 52.6737 87.9834 58.8624 88.7628C63.9808 89.4075 68.0118 93.4385 68.6565 98.557C68.7587 99.3804 69.4583 99.9987 70.288 99.9988H77.712C78.6103 99.9988 79.3424 99.278 79.3562 98.3799C79.5332 87.9079 82.3536 75.8803 90.41 69.8602C118.221 49.0174 110.899 31.1467 104.448 24.6713ZM76.03 56.2011V56.1884L70.7181 53.5199C70.7112 51.6759 72.2006 50.1755 74.0445 50.1686C75.8885 50.1618 77.3889 51.6511 77.3958 53.4951C77.3997 54.5637 76.892 55.5697 76.03 56.2011Z"
        fill={monochrome ? 'currentColor' : 'white'}
      />
      <path
        d="M102.891 58.0974L105.881 72.5352L95.2892 74.7292L93.7358 67.2295C97.523 64.0981 100.535 61.0446 102.891 58.0974ZM119.128 14.125L116.558 1.7198C116.317 0.553519 115.175 -0.19639 114.008 0.0452734L108.418 1.2032C107.251 1.44486 106.77 3.88182 106.77 3.88182C102.4 8.13348 84.8146 9.1268 76.1443 10.9228C67.614 12.6897 61.7363 16.6023 57.9663 24.2245C57.8619 24.4367 57.9373 24.6987 58.1389 24.8223C58.936 25.3115 61.6423 26.3666 62.3187 25.6773C69.0657 18.8009 78.0194 17.1804 82.6357 19.379C83.5728 19.8253 84.1237 20.8247 84.3342 21.8411L89.5407 46.9771C92.4678 47.2684 95.129 46.5 97.6315 43.9837C98.3753 43.2386 98.9782 42.3832 99.4448 41.4619L95.2086 21.0106C94.9013 19.5265 95.7521 18.0672 97.1984 17.6144C100.6 16.5496 105.354 15.8531 109.286 16.0296C109.286 16.0296 110.696 18.075 111.862 17.8333L117.453 16.6754C118.619 16.4337 119.37 15.2924 119.128 14.125Z"
        fill="url(#paint0_linear_1085_496)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1085_496"
          x1="75.1637"
          y1="72.9286"
          x2="96.8443"
          y2="-5.85171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#06A0CE" />
          <stop offset="1" stopColor="#51CBBF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
