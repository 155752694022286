import { LogoPropsType } from './logoTypes';

export default function AndroidShortLogo({ className }: LogoPropsType) {
  return (
    <svg
      width="89"
      height="54"
      viewBox="0 0 89 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <mask id="mask0_2220_19850" maskUnits="userSpaceOnUse" x="0" y="0" width="89" height="54">
        <path
          d="M88.879 47.7418C87.4701 42.0963 85.0046 36.8037 81.8346 32.2167C79.7214 29.394 77.2558 26.5712 74.4381 24.1013C72.677 22.69 70.9159 21.2786 68.8027 19.8672L71.6204 14.9274L74.0859 10.3405L75.847 7.16491C76.9036 5.04786 76.1992 2.57796 74.0859 1.51943C72.3248 0.460907 69.8593 1.16659 68.8027 2.9308L67.0416 6.10639L64.2238 10.6933L61.4061 15.2803L61.0539 15.6331H60.7017C55.4184 13.5161 50.1352 12.8104 44.4997 12.8104H44.1475C38.512 12.8104 33.2288 13.8689 27.9456 15.986L27.5933 15.6331L25.1278 10.6933L22.3101 6.10639L20.1968 2.9308C19.1402 0.813749 16.6746 0.108065 14.5613 1.51943C13.8569 1.87228 13.1525 2.9308 12.8003 3.98933C12.448 5.04786 12.8003 6.10639 13.1525 7.16491L14.9136 10.3405L17.7313 14.9274L20.549 19.5144C13.5047 23.7485 7.86924 30.0997 3.99486 37.1565C2.23378 40.3321 1.17713 43.8605 0.120478 47.3889C-0.583954 50.9174 1.88156 53.7401 5.40372 53.7401H83.9479C87.1179 53.7401 89.5834 50.9174 88.879 47.7418Z"
          fill="white"
        ></path>
      </mask>
      <g mask="url(#mask0_2220_19850)">
        <path d="M90.6414 -0.950195H-6.57031V53.7404H90.2892L90.6414 -0.950195Z" fill="#4FAF53"></path>
        <path
          d="M42.7402 19.8674C51.8978 27.2771 26.1861 27.9827 15.9718 43.8607C6.10973 59.7386 14.2107 87.2603 4.70086 80.2034C-0.9346 71.3824 -1.99125 53.7402 8.22302 37.8623C18.4373 21.6316 34.9915 16.3389 42.7402 19.8674Z"
          fill="url(#paint0_linear_2220_19850)"
          fillOpacity="0.4"
        ></path>
        <path
          d="M46.6149 19.5149C37.1051 26.5717 64.2257 27.2774 74.7922 42.0968C85.3587 57.269 76.9055 83.3793 86.4153 76.3225C92.403 68.2071 93.4597 51.2707 82.8932 36.0985C72.3267 20.9262 55.0681 16.6921 46.6149 19.5149Z"
          fill="url(#paint1_linear_2220_19850)"
          fillOpacity="0.4"
        ></path>
        <g opacity="0.6">
          <path
            d="M-5.51326 42.0968C-9.03542 53.7406 -11.1487 63.6201 -9.73985 63.973C-8.33099 64.3258 -4.45661 55.1519 -0.58223 43.861C2.93993 32.2172 18.4374 19.5149 17.0286 19.162C15.2675 18.4563 -1.99109 30.453 -5.51326 42.0968Z"
            fill="#8BD8A0"
          ></path>
        </g>
        <g opacity="0.5">
          <path
            d="M90.6409 41.0378C94.1631 52.6816 96.2764 62.5612 94.8675 62.914C93.4586 63.2669 89.5842 54.093 85.7099 42.802C82.1877 31.1582 66.6902 18.4559 68.0991 18.103C69.5079 17.7502 87.1187 29.394 90.6409 41.0378Z"
            fill="#8BD8A0"
          ></path>
        </g>
        <g opacity="0.7">
          <path
            d="M16.3234 9.98835C19.8456 15.9867 21.6067 18.8094 20.9022 19.1622C20.55 19.5151 18.0845 17.0452 14.5623 11.0469C8.92687 6.10708 14.9145 -0.244084 15.2668 -0.596926C15.9712 -0.949769 11.0402 1.87297 16.3234 9.98835Z"
            fill="#0D652D"
          ></path>
        </g>
        <g opacity="0.1">
          <path
            d="M20.5496 19.8671C20.9018 19.5143 18.7885 15.2802 15.2664 10.3404C12.0964 5.04776 8.92648 1.16649 8.57426 1.51933C8.22205 1.87217 10.3353 6.10628 13.8575 11.0461C17.0275 16.3387 20.1974 19.8671 20.5496 19.8671Z"
            fill="#202124"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M20.9028 6.81263C24.7772 13.1638 26.5383 15.9865 27.2427 15.6337C27.5949 15.2808 26.5383 12.1053 22.6639 5.7541C19.1417 -2.71411 12.4496 2.22568 13.1541 3.28421C14.2107 5.7541 15.9718 -2.00843 20.9028 6.81263Z"
            fill="white"
          ></path>
        </g>
        <path
          opacity="0.15"
          d="M9.27912 4.69554C9.98356 7.16544 12.4491 8.22397 15.2668 7.51828C17.7323 6.8126 19.4934 4.3427 18.789 1.87281C18.0845 -0.597092 15.619 -1.65562 12.8013 -0.949934C9.98356 -0.24425 8.57469 2.22565 9.27912 4.69554Z"
          fill="url(#paint2_radial_2220_19850)"
          fillOpacity="0.9"
        ></path>
        <path
          opacity="0.15"
          d="M70.2125 3.98949C70.9169 6.45939 73.7346 7.51791 76.5524 6.81223C79.3701 6.10654 81.1312 3.2838 80.4267 1.16675C79.7223 -1.30315 76.9046 -2.36167 74.0868 -1.65599C71.2691 -0.950304 69.508 1.51959 70.2125 3.98949Z"
          fill="url(#paint3_radial_2220_19850)"
          fillOpacity="0.9"
        ></path>
        <g opacity="0.1">
          <path
            d="M68.4514 20.2204C68.0991 19.8675 70.2124 15.6334 73.3824 10.6936C76.5523 5.75382 79.3701 1.87256 80.0745 1.87256C80.7789 1.87256 78.3134 6.45951 75.1435 11.3993C71.9735 16.3391 68.8036 20.2204 68.4514 20.2204Z"
            fill="#202124"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M70.5635 19.5148C66.6891 17.7506 63.5191 17.0449 63.1669 17.3978C62.8147 17.7506 65.9846 19.162 69.859 20.9262C73.7334 22.6904 76.9033 23.7489 77.2556 23.3961C77.2556 22.6904 74.4378 20.9262 70.5635 19.5148Z"
            fill="#0D652D"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M62.815 16.3395C61.7583 15.9866 60.7017 15.6338 60.7017 15.6338C60.7017 15.9866 61.4061 16.3395 62.4627 16.6923C63.5194 17.398 64.576 17.7508 64.576 17.398C64.576 17.398 63.8716 16.6923 62.815 16.3395Z"
            fill="#81C995"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M18.0849 19.8674C21.9593 18.1032 25.1293 17.0447 25.4815 17.7503C25.8337 18.1032 22.6638 19.5146 19.1416 21.2788C15.6194 23.043 12.0973 24.4543 11.7451 23.7487C11.3928 23.043 14.2106 21.6316 18.0849 19.8674Z"
            fill="#0D652D"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M25.8333 16.6915C26.89 15.9858 27.9466 15.9858 27.9466 15.9858C27.9466 15.9858 27.2422 16.6915 25.8333 16.6915C25.1289 17.7501 24.4245 18.1029 24.0723 18.1029C24.0723 17.7501 24.7767 17.0444 25.8333 16.6915Z"
            fill="#81C995"
          ></path>
        </g>
        <g opacity="0.7">
          <path
            d="M73.0297 10.3409C69.5076 16.3392 68.0987 19.1619 68.4509 19.5148C69.1554 19.8676 71.2687 17.3977 74.7908 11.3994C80.0741 6.45962 74.0864 0.108455 73.7342 -0.244387C73.3819 -0.59723 78.313 2.22551 73.0297 10.3409Z"
            fill="#0D652D"
          ></path>
        </g>
        <g opacity="0.3">
          <path
            d="M68.0995 7.16526C64.5773 13.5164 62.8162 16.3392 62.464 15.9863C62.1118 15.6335 63.1684 12.4579 66.6906 6.10674C70.9172 -4.83138 78.666 -3.42001 77.6093 2.57831C76.2004 5.04821 72.6783 -1.6558 68.0995 7.16526Z"
            fill="white"
          ></path>
        </g>
        <path
          d="M27.2422 35.0399C27.9467 34.3342 27.2422 32.9229 26.1856 31.8643C24.7767 30.8058 23.3679 30.453 22.6634 31.1586C21.959 31.8643 22.6634 33.2757 23.7201 34.3342C25.129 35.3928 26.5378 35.7456 27.2422 35.0399Z"
          fill="url(#paint4_radial_2220_19850)"
          fillOpacity="0.7"
        ></path>
        <path
          d="M67.0429 34.3342C68.0995 33.2757 68.8039 31.8643 68.0995 31.1586C67.3951 30.453 65.9862 30.8058 64.9296 31.8643C63.8729 32.9229 63.1685 34.3342 63.8729 35.0399C64.2251 35.7456 65.634 35.3928 67.0429 34.3342Z"
          fill="url(#paint5_radial_2220_19850)"
          fillOpacity="0.7"
        ></path>
        <path
          opacity="0.09"
          d="M26.5375 39.2736C27.9464 35.7451 26.5375 33.2753 25.4809 32.5696C22.6631 29.0411 19.141 31.8639 18.0843 33.2753C17.0277 34.6866 15.2666 37.1565 17.0277 40.6849C18.7888 44.5662 24.7764 43.8605 26.5375 39.2736ZM64.2247 39.2736C62.8158 35.7451 64.2246 33.2753 65.2813 32.9224C68.099 29.394 71.6212 32.2167 72.6778 33.6281C73.7345 35.0395 75.4956 37.5094 73.7345 41.0378C72.3256 44.5662 65.9857 43.8605 64.2247 39.2736Z"
          fill="#011B04"
        ></path>
        <path
          d="M72.3264 41.744C74.4397 40.3327 74.7919 36.8042 73.0308 33.9815C71.2697 31.1588 67.7476 30.1002 65.6343 31.8645C63.521 33.2758 63.1687 36.8043 64.9298 39.627C66.6909 42.4497 70.2131 43.5083 72.3264 41.744Z"
          fill="#202124"
        ></path>
        <path
          d="M25.833 39.6261C27.5941 36.8034 27.2419 33.6278 25.1286 31.8636C23.0153 30.4522 19.4932 31.5108 17.7321 34.3335C15.971 37.1562 16.3232 40.3318 18.4365 42.096C20.902 43.1546 24.072 42.096 25.833 39.6261ZM64.9291 39.6261C63.168 36.8034 63.5202 33.6278 65.6335 31.8636C67.7468 30.4522 70.9167 31.5108 73.03 33.9807C74.7911 36.8034 74.4389 39.979 72.3256 41.7432C70.2123 43.1546 66.6901 42.4489 64.9291 39.6261Z"
          fill="black"
        ></path>
        <path
          d="M18.4372 33.9806C16.6761 36.0977 16.6761 38.5676 16.6761 39.6261C16.3239 37.5091 16.3239 35.392 18.4372 33.9806C19.1416 32.5693 20.5505 31.8636 21.2549 31.5107C20.9027 31.5107 19.846 32.2164 18.4372 33.9806Z"
          fill="black"
        ></path>
        <path
          opacity="0.8"
          d="M26.1851 34.3338C25.4807 31.1582 23.0151 31.1582 21.9585 31.1582C23.0151 30.8054 24.7762 31.1582 25.4807 32.2167C26.1851 32.9224 26.1851 33.9809 26.1851 34.3338Z"
          fill="url(#paint6_linear_2220_19850)"
        ></path>
        <path
          d="M67.7473 31.864C65.2817 31.864 64.2251 33.9811 64.2251 35.0396C64.5773 34.3339 65.634 32.9225 67.0428 32.5697C68.8039 32.2169 70.9172 33.9811 71.6216 35.0396L72.3261 33.9811C71.9739 33.2754 70.565 31.5112 67.7473 31.864Z"
          fill="url(#paint7_linear_2220_19850)"
        ></path>
        <path
          d="M72.3256 33.9806C74.0866 36.0977 74.4388 38.5676 74.0866 39.6261C74.4388 37.5091 74.4389 35.392 72.6778 33.6278C71.6211 32.5693 70.2123 31.8636 69.5078 31.5107C70.2123 31.5107 70.9167 32.2164 72.3256 33.9806Z"
          fill="black"
        ></path>
        <path
          opacity="0.8"
          d="M20.9024 32.9229C19.8457 33.2757 19.1413 34.3342 18.7891 34.6871L19.1413 35.3927C19.1413 35.3927 19.4935 34.6871 19.8457 34.3342C20.5501 34.3342 20.9024 33.6285 21.2546 33.6285L20.9024 32.9229ZM70.2126 32.9229C71.2693 33.2757 71.9737 33.9814 72.3259 34.6871L71.9737 35.3927C71.9737 35.3927 71.6215 34.6871 71.2693 34.3342C70.9171 33.9814 70.2126 33.6285 69.8604 33.2757L70.2126 32.9229Z"
          fill="#E2DCE1"
        ></path>
        <g opacity="0.8">
          <path
            d="M24.7762 38.5684C24.424 38.9212 23.7196 39.2741 23.3674 39.2741L23.0151 39.9798C23.7196 39.6269 24.424 39.2741 24.424 39.2741L24.7762 38.5684ZM26.5373 34.6871C26.5373 34.3343 26.5373 34.3343 26.1851 33.9814L26.5373 34.6871C26.1851 34.6871 26.5373 35.04 26.5373 35.3928C26.5373 35.04 26.5373 35.04 26.5373 34.6871Z"
            fill="#E2DCE1"
          ></path>
        </g>
        <g opacity="0.8">
          <path
            d="M65.9857 38.5684C66.3379 38.9212 67.3946 39.2741 67.3946 39.2741L67.7468 39.9798C67.0423 39.6269 66.6901 39.6269 66.3379 39.2741L65.9857 38.5684ZM64.2246 34.6871C64.2246 34.3343 64.5768 33.9814 64.5768 33.9814V34.3343C64.5768 34.6871 64.2246 35.04 64.2246 35.3928C64.2246 35.04 64.2246 35.04 64.2246 34.6871Z"
            fill="#E2DCE1"
          ></path>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2220_19850"
          x1="6.65003"
          y1="39.2811"
          x2="20.4672"
          y2="47.9319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8F0B9"></stop>
          <stop offset="0.807" stopColor="#ADEEBC" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_2220_19850"
          x1="83.505"
          y1="36.1556"
          x2="69.8936"
          y2="45.6385"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.292" stopColor="#A8F0B9"></stop>
          <stop offset="0.828" stopColor="#ADEEBC" stopOpacity="0"></stop>
        </linearGradient>
        <radialGradient
          id="paint2_radial_2220_19850"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.934 3.37583) rotate(163.29) scale(3.09052 2.34205)"
        >
          <stop stopColor="white"></stop>
          <stop offset="0.948" stopColor="white" stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint3_radial_2220_19850"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(75.524 2.4343) rotate(163.311) scale(3.32111 2.41023)"
        >
          <stop stopColor="white"></stop>
          <stop offset="0.948" stopColor="white" stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint4_radial_2220_19850"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.774 33.3058) rotate(132.922) scale(1.24749 2.27018)"
        >
          <stop stopColor="#93E19F"></stop>
          <stop offset="1" stopColor="#93E19F" stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint5_radial_2220_19850"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(66.9742 32.6156) rotate(47.0684) scale(1.24623 2.26919)"
        >
          <stop stopColor="#93E19F"></stop>
          <stop offset="1" stopColor="#93E19F" stopOpacity="0"></stop>
        </radialGradient>
        <linearGradient
          id="paint6_linear_2220_19850"
          x1="24.0767"
          y1="29.9973"
          x2="24.2605"
          y2="34.2773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2DDE2"></stop>
          <stop offset="1" stopColor="#E2DDE2" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_2220_19850"
          x1="68.396"
          y1="31.815"
          x2="68.396"
          y2="35.3257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#373637"></stop>
          <stop offset="1" stopColor="#373637" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
